import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ShareIcon from '@mui/icons-material/Share';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Avatar from '@mui/material/Avatar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { SecureFile, Tag } from '../interfaces/models';
import { formatBytes, handleErrors } from '../utils/utils';
import { FileUploadModal } from './FileUploadModal';
import PermMediaIcon from '@mui/icons-material/PermMedia';


interface Params {
  projectId: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Project = () => {
  const baseUrl = window.location.origin;
  const { projectId } = useParams<Params>();
  const query = useQuery();
  const history = useHistory();

  const [files, setFiles] = useState<SecureFile[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const popId = open ? 'simple-popover' : undefined;

  useEffect(() => {
    getFiles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFiles = () => {
    const queryParams = query.toString().length > 0 ? '?' + query.toString() : '';

    fetch(`${baseUrl}/api/projects/${projectId}/files${queryParams}`)
    .then(res => handleErrors(res))
    .then(result => {
      setError(null);
      setIsLoaded(true);

      setFiles(result);
      uniquify(result);
    })
    .catch(error => {
      setIsLoaded(true);

      switch (error.message) {
        case '400':
          setError('Failed to load files. Invalid project.');
          break;
        case '401':
          setError("You're not allowed to view files in this project.");
          break;
        case '403':
          setError("You're not allowed to view files in this project.");
          break;
        case '404':
          setError('No files found in this project.');
          break;
        default:
          setError('Failed to load the project.');
          break;
      }
    });
  }

  const uniquify = (files: SecureFile[]) => {
    let arr: Tag[] = files.map(f => f.tags).flat();
    const ids = arr.map(t => t.name + t.value);

    arr = arr.filter((tag, index) => !ids.includes(tag.name + tag.value, index + 1));
    arr = arr.sort((a: Tag, b: Tag) => a.name === b.name ? b.value > a.value ? 1 : -1 :  a.name > b.name ? 1 : -1);

    setTags(arr);
  }

  const toggleTag = (tag: Tag) => () => {
    if (query?.get(tag.name)) {
      query.delete(tag.name);
    } else {
      query.append(tag.name, tag.value);
    }

    history.replace({
      search: query.toString(),
    });

    getFiles();
  }

  const previewFile = (id: string) => (e: any) => {
    e.stopPropagation();
    history.push(`/files/${id}`);
  }

  // const download = (id: string, name: string) => (e: any) => {
  //   e.stopPropagation();
  //   downloadFile(id, name);
  // }

  const copyToClipboard = (id: string) => (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);

    navigator.clipboard.writeText(`${baseUrl}/api/files/${id}/view`);

    setTimeout(() => setAnchorEl(null), 1500);
  }

  const handleClosePopover = () => {
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      {isLoaded
      ?
        <React.Fragment>
          {error
          ?
            <div className="border border-2 rounded p-5 d-flex flex-column align-items-center text-muted mt-4">
              <WarningAmberIcon sx={{ fontSize: 80 }} />
              <h5 className="mt-4">{error}</h5>
            </div>
          :
            <div>
              <div className="d-flex justify-content-between">
                <Breadcrumbs aria-label="breadcrumb" className="mb-2">
                  <Link underline="hover" color="inherit" href="/">
                    Projects
                  </Link>
                  <Typography color="text.primary">{projectId}</Typography>
                </Breadcrumbs>

                <FileUploadModal onUploaded={getFiles} />
              </div>

              <div>
                {tags && tags.map((tag) => (
                  <Chip
                  key={`${tag.name} + ${tag.value}`}
                  label={`${tag.name}: ${tag.value}`}
                  clickable
                  color={query?.get(tag.name) ? 'success' : 'default'}
                  onClick={toggleTag(tag)}
                  size="small"
                  className="me-2"
                  />
                ))}
              </div>

              {files?.length > 0
              ?
                <List>
                  {files.map((file) => (
                    <ListItem key={file.id}>
                      <ListItemButton onClick={previewFile(file.id)}>
                        <ListItemAvatar>
                          <Avatar>
                            {file?.contentType.startsWith('text/')
                            ?
                              <DescriptionIcon/>
                            :
                            file?.contentType.startsWith('image/')
                            ?
                              <ImageIcon/>
                            :
                            file?.contentType.startsWith('video/')
                            ?
                              <MovieCreationIcon/>
                            :
                            file?.contentType.endsWith('/pdf')
                            ?
                              <PictureAsPdfIcon/>
                            :
                              <InsertDriveFileIcon/>
                            }
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          primary={file.name}
                          secondary={file.tags.map(t => t.value).join(' | ')}
                        />

                        <Typography color="text.primary" className="me-4 mt-1">{formatBytes(file.length)}</Typography>

                        {/* <ListItemIcon>
                          <IconButton aria-label="download" onClick={download(file.id, file.name)}>
                            <CloudDownloadIcon />
                          </IconButton>
                        </ListItemIcon> */}

                        <ListItemIcon>
                          <IconButton
                            aria-describedby={popId}
                            component="button"
                            onClick={copyToClipboard(file.id)}
                            title="Copy link to clipboard"
                            >
                            <ShareIcon />
                          </IconButton>
                          <Popover
                            id={popId}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                          >
                            <Typography sx={{ p: 2 }}>Link copied to clipboard</Typography>
                          </Popover>
                        </ListItemIcon>

                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              :
                <div className="border border-2 rounded p-5 d-flex flex-column align-items-center text-muted mt-4">
                  <PermMediaIcon sx={{ fontSize: 80 }} />
                    <h5 className="mt-4">No files in this project.</h5>
                </div>
              }
            </div>
          }
        </React.Fragment>
      :
      <div className="w-100 d-flex justify-content-center">
        <CircularProgress />
      </div>
      }
    </React.Fragment>
  );
}
