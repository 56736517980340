import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import OutlinedInput from '@mui/material/OutlinedInput';
import Popover from '@mui/material/Popover';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SecureFile, Tag } from '../interfaces/models';
import { formatBytes, handleErrors } from '../utils/utils';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  borderRadius: '5px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

interface Params {
  projectId: string;
}

export const FileUploadModal= ({onUploaded}: any) => {
  const baseUrl = window.location.origin;
  const { projectId } = useParams<Params>();

  const fileInput = React.createRef<any>();
  const [file, setFile] = useState<File>();
  const [tags, setTags] = useState<Tag[]>([]);
  const [uploadedFile, setUploadedFile] = useState<SecureFile>();

  const [isLoading, setIsLoading] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);


  const [errorEnabled, setErrorEnabled] = useState(false);
  const [name, setName] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const handleNameChange = (e: SelectChangeEvent<string>) => setName(e.target.value);
  const handleValueChange = (e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => setValue(e.currentTarget.value);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setTimeout(() => {
      resetState();
    }, 500);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const addTag = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setErrorEnabled(true);

    if (name.length > 0 && value.length > 0) {
      tags.push({name: name, value: value});
      setName('');
      setValue('');
      setErrorEnabled(false);
    }
  }

  const deleteTag = (tag: Tag) => () => {
    setTags(tags.filter(t => t !== tag));
  }

  function handleFileChange(event: any) {
    event.preventDefault();

    setFile(fileInput.current.files[0]);
  }

  const uploadFile = () => {
    if (!file) {
      setErrorEnabled(true);
      return;
    }

    setIsLoading(true);

    const reader = new FileReader();

    let queryParams = '';

    if (tags.length > 0) {
      queryParams = '?' + tags.map(t => t.name + '=' + t.value).join('&')
      console.log(queryParams)
    }

    reader.onload = function() {
      fetch(`${baseUrl}/api/projects/${projectId}/files${queryParams}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': file.type,
          'Filename': file.name,
        },
        body: reader.result,
      })
      .then(res => handleErrors(res))
      .then(result => {
        setOpenSuccess(true);
        setUploadedFile(result);
        onUploaded();
      })
      .catch(error => {
        setOpenError(true);
        setIsLoading(false);
      });
    }

    reader.readAsArrayBuffer(file);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const popId = open ? 'simple-popover' : undefined;

  const copyToClipboard = (e: any) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    navigator.clipboard.writeText(uploadedFile ? uploadedFile.location : '');

    setTimeout(() => setAnchorEl(null), 1500);
  }

  const handleClosePopover = () => {
    setAnchorEl(null);
  }

  const resetState = () => {
    setFile(undefined);
    setUploadedFile(undefined);
    setTags([]);
    setName('')
    setValue('');
    setErrorEnabled(false);
    setIsLoading(false);
  }

  return (
    <div>
      <Button onClick={handleOpenModal} variant="contained" color="primary">Upload File</Button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>

            <Typography id="transition-modal-title" variant="h6" component="h2">
              Upload File
            </Typography>


            {uploadedFile
            ?
              <div className="mt-4">
                <div className="d-flex justify-content-between">
                  <h5 className="m-0 d-flex align-items-center">
                    File successfully uploaded
                    <CheckCircleOutlineIcon className="ms-2 text-success" />
                  </h5>

                  <Link href={`/files/${uploadedFile?.id}`} underline="hover" className="d-flex align-items-center">
                    <span className="mt-1">View file</span>
                    <OpenInNewIcon fontSize="small" className="ms-2" />
                  </Link>
                </div>

                <div className="mt-4">Copy this link to share the file in Jira.</div>

                <OutlinedInput
                  readOnly
                  value={uploadedFile?.location}
                  className="w-100 mt-2"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-describedby={popId}
                        onClick={copyToClipboard}
                        title="Copy link to clipboard"
                        >
                        <ContentCopyIcon />
                      </IconButton>
                      <Popover
                        id={popId}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                      >
                        <Typography sx={{ p: 2 }}>Link copied to clipboard</Typography>
                      </Popover>
                    </InputAdornment>
                  }
                />

                <div className="d-flex justify-content-end mt-4">
                  <Button className="me-3" onClick={handleCloseModal}>Close</Button>
                  <Button variant="contained" color="primary" onClick={resetState}>Add File</Button>
                </div>
              </div>
            :
              <React.Fragment>
                <div className="d-flex align-items-center justify-content-between mt-4">
                  <div>
                    {file &&
                      <span className="me-2"><b>{file.name}</b> - {formatBytes(file.size)}</span>
                    }
                  </div>

                  <input
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    ref={fileInput}
                    onChange={handleFileChange}
                    disabled={isLoading}
                  />
                  <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span" disabled={isLoading}>
                      Choose File
                    </Button>
                  </label>
                </div>

                <div className="mt-4">
                  {tags && tags.map((tag) => (
                    <Chip
                      key={tag.name}
                      label={`${tag.name}: ${tag.value}`}
                      onDelete={deleteTag(tag)}
                      size="small"
                      className="me-2"
                    />
                  ))}
                </div>

                <form onSubmit={addTag} className="mt-4 d-flex justify-content-between align-items-center">
                  <FormControl
                    variant="standard"
                    className="me-2"
                    sx={{ width: '44%' }}
                  >
                    <InputLabel id="key-select-label">Key</InputLabel>
                    <Select
                      labelId="key-select-label"
                      value={name}
                      onChange={handleNameChange}
                      label="Key"
                    >
                      <MenuItem value="issueId" disabled={ tags.findIndex(t => t.name === 'issueId') > -1 }>Issue ID</MenuItem>
                      <MenuItem value="testRun" disabled={ tags.findIndex(t => t.name === 'testRun') > -1 }>Test Run</MenuItem>
                      <MenuItem value="release" disabled={ tags.findIndex(t => t.name === 'release') > -1 }>Release</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    label="Value"
                    value={value}
                    onChange={handleValueChange}
                    variant="standard"
                    className="me-2"
                    sx={{ width: '44%' }}
                    error={errorEnabled && value.length < 1}
                    disabled={ tags.length > 2 }
                  />
                  <IconButton type="submit" sx={{ width: '40px', height: '40px'}} disabled={ tags.length > 2 }>
                    <ControlPointIcon color={ tags.length > 2 ? 'disabled' : 'success'} />
                  </IconButton>
                </form>

                <div className="d-flex justify-content-end mt-5">
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    component="span"
                    loading={isLoading}
                    disabled={isLoading || (errorEnabled && !file)}
                    onClick={uploadFile}
                  >
                    Upload
                  </LoadingButton>
                </div>
              </React.Fragment>
            }
          </Box>
        </Fade>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSuccess}
        onClose={handleCloseSuccess}
        autoHideDuration={5000}
      >
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
          File successfully uploaded.
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openError}
        onClose={handleCloseError}
        autoHideDuration={5000}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          File upload failed.
        </Alert>
      </Snackbar>
    </div>
  );
}
