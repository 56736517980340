import Button from '@mui/material/Button';
import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Projects } from './Projects';


export const Home = () => {
  const { isAuthenticated, login } = useAuth();


  return (
    <React.Fragment>

      {isAuthenticated
      ?
        <Projects />
      :
        <div className="d-flex flex-column align-items-center mt-5">
          <h1>Welcome to Secure Data Store</h1>
          <Button onClick={login} variant="contained" color="primary" className="px-5 mt-4">Sign In</Button>
        </div>
      }
    </React.Fragment>
  );
}
