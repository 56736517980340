import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { handleErrors } from '../utils/utils';

interface Project {
  name: string,
  key: string,
}

export const Projects = () => {
  const mountedRef = useRef(true)

  const [projects, setProjects] = useState<Project[]>([]);
  const [error, setError] = useState<any | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const baseUrl = window.location.origin;

  const history = useHistory();

  useEffect(() => {
    fetch(`${baseUrl}/api/projects`)
    .then(res => handleErrors(res))
    .then(result => {
      setError(null);
      setProjects(result);
      setIsLoaded(true);
    })
    .catch(error => {
      setIsLoaded(true);
      setError(error);
    })

    return () => { mountedRef.current = false }
  }, [baseUrl]);

  const navigate = (key: string) => () => {
    history.push(`/projects/${key}/files`);
  }

  return (
    <div>
      <h2>Projects</h2>

      {isLoaded
      ?
        <React.Fragment>
          {error
          ?
            <div className="border border-2 rounded p-5 d-flex flex-column align-items-center text-muted mt-4">
              <WarningAmberIcon sx={{ fontSize: 80 }} />
              <h5 className="mt-4">Failed to load projects. {error.message}</h5>
            </div>
          :
            <List>
            {projects.map((project) => (
              <ListItemButton onClick={navigate(project.key)} key={project.key} className="w-100">
                <ListItemText primary={project.name} secondary={project.key} />
              </ListItemButton>
            ))}
            </List>
          }
        </React.Fragment>
      :
        <div className="w-100 d-flex justify-content-center">
          <CircularProgress />
        </div>
      }
    </div>
  );
}
