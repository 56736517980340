import React, { useState, useEffect, useContext } from "react";

interface Props {
  children: React.ReactNode;
}

interface authContext {
  isAuthenticated: boolean,
  user: any,
  isLoading: boolean,
  login: any,
  logout: any
}

const defaultValue: authContext = {
  isAuthenticated: false,
  user: undefined,
  isLoading: true,
  login: undefined,
  logout: undefined
}

export const AuthContext = React.createContext(defaultValue);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({children}: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getUser = async () => {
    const response = await fetch('/account/user');
    const res = await response.json();

    setIsAuthenticated(res.isAuthenticated);
    setIsLoading(false);

    if (res.isAuthenticated) {
      setUser(res.claims);
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  const login = () => {
    if (window.location.pathname.length > 1) {
      const redirect = encodeURIComponent(window.location.pathname)
      window.location.href = `/account/login?ReturnUrl=${redirect}`;
    } else {
      window.location.href = '/account/login';
    }
  }

  const logout = () => {
    window.location.href = '/account/logout';
  }

  return (
    <AuthContext.Provider
      value={{
          isAuthenticated,
          user,
          isLoading,
          login,
          logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
