import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { Route, Switch } from 'react-router';
import { FilePreview } from './components/FilePreview';
import { Home } from './components/Home';
import { Layout } from './components/Layout';
import { NotFound } from './components/NotFound';
import { Project } from './components/Project';
import { User } from './components/User';
import { useAuth } from './context/AuthContext';
import './custom.css';

const theme = createTheme({
  typography: {
    fontFamily: [
      'HCo Verlag SSm',
      'sans-serif'
    ].join(','),
  },
  palette: {
    primary: {
      main: '#001489',
      dark: '#141E55',
    },
    text: {
      primary: '#141E55'
    },
    background: {
      default: '#FFF'
    },
  }
});


const App = () => {
  const { isLoading, isAuthenticated, login } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        {isLoading
          ?
          <div className="w-100 d-flex justify-content-center">
            <CircularProgress />
          </div>
          :
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/projects/:projectId/files' component={ isAuthenticated ? () => { return <Project /> } : () => { login(); return null; }} />
            <Route path='/files/:id' component={ isAuthenticated ? () => { return <FilePreview /> } : () => { login(); return null; }} />
            <Route path='/user' component={ isAuthenticated ? () => { return <User /> } : () => { login(); return null; }} />
            <Route path='/login' component={() => { login(); return null }} />
            <Route component={NotFound} />
          </Switch>
        }
      </Layout>
    </ThemeProvider>
  );
}

export default App;
