import Container from '@mui/material/Container';
import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div style={{minHeight: '100vh'}} className="d-flex flex-column">
        <NavMenu />
        <Container className="flex-grow-1">
          {this.props.children}
        </Container>
        <Footer />
      </div>
    );
  }
}
