import Link from '@mui/material/Link';
import React from 'react';



export const NotFound = () => {
  return (
    <div>
      <h1>404 - Not Found!</h1>
      <Link href="/">
        Go Home
      </Link>
    </div>
  );
}
