import AccountCircle from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { useAuth } from '../context/AuthContext';


export const NavMenu = () => {
  const { isAuthenticated, user, login } = useAuth();

  return (
    <header>
      <AppBar position="static" className="mb-4">
        <Toolbar>
          <div className="flex-grow-1">
            <Link href="/" underline="none" color="inherit" variant="h6" sx={{ "&:hover": {color: 'inherit'} }}>
              Secure Data Store
            </Link>
          </div>

          {isAuthenticated
          ?
            <div className="d-flex align-items-center">
              <AccountCircle className="me-2"/>
              <div>{user?.find((c: any) => c.type === 'name')?.value}</div>
            </div>
          :
            <IconButton
              size="large"
              aria-label="login"
              onClick={login}
              color="inherit"
            >
              <LoginIcon />
            </IconButton>
          }
        </Toolbar>
      </AppBar>
    </header>
  );
}
