import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Outline, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useParams } from "react-router-dom";
import { SecureFile } from '../interfaces/models';
import { downloadFile, handleErrors } from '../utils/utils';
import './PdfViewer.css';

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
}

interface Params {
  id: string;
}

export const FilePreview = () => {
  const mountedRef = useRef(true)

  const { id } = useParams<Params>();
  const [error, setError] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [file, setFile] = useState<SecureFile>();

  const baseUrl = window.location.origin;

  const [pdf, setPdf] = useState<any | null>(null);
  const [numPages, setNumPages] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const popId = open ? 'simple-popover' : undefined;

  const copyToClipboard = (e: any) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    navigator.clipboard.writeText(`${baseUrl}/api/files/${id}/view`);

    setTimeout(() => setAnchorEl(null), 1500);
  }

  const handleClosePopover = () => {
    setAnchorEl(null);
  }

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const onItemClicked =	({ pageNumber }: any) => {
    window.location.hash = "#" + pageNumber;
  }

  const download = (id: string, name: string) => (e: any) => {
    e.stopPropagation();
    downloadFile(id, name);
  }

  useEffect(() => {
    fetch(`${baseUrl}/api/files/${id}/info`)
    .then(res => handleErrors(res))
    .then(result => {
      setError(null);
      setIsLoaded(true);
      setFile(result);

      if(result.contentType.includes('pdf')) {
        setPdf({url: `${baseUrl}/api/files/${id}/download`});
      }
    })
    .catch(error => {
      setIsLoaded(true);

      switch (error.message) {
        case '400':
          setError('Failed to load the file. Invalid file-id.');
          break;
        case '401':
          setError("You're not allowed to view this file.");
          break;
        case '403':
          setError("You're not allowed to view this file.");
          break;
        case '404':
          setError('File not found.');
          break;
        default:
          setError('Failed to load the file.');
          break;
      }
    })

    return () => { mountedRef.current = false }
  }, [id, baseUrl]);

  return (
    <div className="pb-4">
      {isLoaded
      ?
        <div>
          {error
          ?
            <div className="border border-2 rounded p-5 d-flex flex-column align-items-center text-muted mt-4">
              <PermMediaIcon sx={{ fontSize: 80 }} />
              <h5 className="mt-4">{error}</h5>
            </div>
          :
            <React.Fragment>
              <div className="d-flex justify-content-between align-items-center">
                <Breadcrumbs aria-label="breadcrumb" className="mb-2">
                  <Link underline="hover" color="inherit" href="/">
                    Projects
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    href={`/projects/${file?.key}/files`}
                  >
                    {file?.key}
                  </Link>
                  <Typography color="text.primary">{file?.name}</Typography>
                </Breadcrumbs>

                {file && !file?.contentType.startsWith('image/') && !file?.contentType.endsWith('/pdf') &&
                  <IconButton title="Download" className="me-2" onClick={download(id, file?.name)}>
                    <CloudDownloadIcon />
                  </IconButton>
                }
              </div>

              <div>
                {file?.tags && file.tags.map((tag) => (
                  <Chip
                    key={`${tag.name} + ${tag.value}`}
                    label={`${tag.name}: ${tag.value}`}
                    component="a"
                    clickable
                    href={`${baseUrl}/projects/${file?.key}/files?${tag.name}=${tag.value}`}
                    size="small"
                    className="me-2"
                  />
                ))}
              </div>

              <div className="mt-4">Copy this link to share the file in Jira.</div>
              <div className="d-flex align-items-center">
                <Link className="me-2">{file?.location}</Link>
                <IconButton
                  aria-describedby={popId}
                  onClick={copyToClipboard}
                  title="Copy link to clipboard"
                  >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
                <Popover
                  id={popId}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 2 }}>Link copied to clipboard</Typography>
                </Popover>
              </div>

              {file?.contentType.startsWith('image/')
              ? <img src={`${baseUrl}/api/files/${id}/download`} alt={file.name} className="w-100 mt-4"/>
              :
                file?.contentType.startsWith('video/')
              ? <video src={`${baseUrl}/api/files/${id}/download`} className="w-100 mt-4" controls/>
              :
                file?.contentType.endsWith('/pdf')
              ?
                <div className="pdf__container">
                  <div className="pdf__container__document">
                    <Document
                      file={pdf}
                      onLoadSuccess={onDocumentLoadSuccess}
                      options={options}
                      className="position-relative"
                    >
                      <Outline onItemClick={onItemClicked} className="sticky-top align-self-start pt-4" />
                      <div>
                      {
                        Array.from(
                          new Array(numPages),
                          (el, index) => (
                            <div id={`${index + 1}`} key={`page_${index + 1}`}>
                              <Page
                                key={`page_${index + 1}`}
                                pageIndex={index}
                              />
                            </div>
                          ),
                        )
                      }
                      </div>
                    </Document>
                  </div>
                </div>
                :
                <div className="border border-2 rounded p-5 d-flex flex-column align-items-center text-muted mt-4">
                  <PermMediaIcon sx={{ fontSize: 80 }} />
                  <h5 className="mt-4">Sorry, we're not able to render the file.
                    {file &&
                      <span> Click <Link component="button" sx={{marginTop: '-4px'}} onClick={download(id, file?.name)}>download</Link> to view the file.</span>
                    }
                  </h5>
                </div>
              }
            </React.Fragment>
          }
        </div>
      :
        <div className="w-100 d-flex justify-content-center">
          <CircularProgress />
        </div>
      }

    </div>
  );
}
